import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createBlock(_component_BaseContainer, { class: "pt-4 lg:pt-6" }, {
    default: _withCtx(() => [
      _createVNode(_component_BackButton, { class: "back-button" }, {
        default: _withCtx(() => [
          (_ctx.searchTerm)
            ? (_openBlock(), _createBlock(_component_BaseHeading, {
                key: 0,
                size: 4,
                class: "back-button-text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('search_term_title', { searchTerm: _ctx.searchTerm })), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_BaseHeading, {
                key: 1,
                size: 4,
                class: "back-button-text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('search_no_term_back_button')), 1)
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}