import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createBlock(_component_BaseContainer, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseHeading, {
        size: 5,
        class: "mt-5 lg:mt-8"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('search_no_results')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}