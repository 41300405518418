
import { defineComponent } from 'vue';
import { routes } from '@/shared/constants/routes';
import { useTranslations } from '@/shared/composables/useTranslations';

export default defineComponent({
  name: 'SearchInfoBlock',
  props: {
    productsLength: { type: Number, default: 0 },
    searchTerm: { type: String, default: '' },
  },
  setup() {
    const { t } = useTranslations();

    return { routes, t };
  },
});
