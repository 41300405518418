
import { defineComponent, onMounted, watch, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import { routes } from '@/shared/constants/routes';
import { useSearch } from './composables/useSearch';
import ProductsInfiniteScroll from '@/shared/components/InfiniteScroll/ProductsInfiniteScroll.vue';
import SearchNoResults from './components/SearchNoResults.vue';
import SearchInfoBlock from './components/SearchInfoBlock.vue';
import Error from '@/pages/Error.vue';

export default defineComponent({
  name: 'Search',
  components: {
    ProductsInfiniteScroll,
    SearchInfoBlock,
    SearchNoResults,
    Error,
  },
  setup() {
    const pageSize = 12;
    const route = useRoute();
    const { products, error, searchProducts, isLoading, isLastPage } = useSearch();
    const isLoadable = computed(() => isLoading.value || isLastPage.value);
    const isNoResults = computed(() => !isLoading.value && !products.value.length);
    const term = computed(() => route.query.term?.toString() || '');

    const state = reactive({
      page: 1,
    });

    onMounted(async () => {
      const options = { term: term.value, page: 1, pageSize, resetProducts: true };
      searchProducts(options);
    });

    const onLoadMore = async () => {
      if (!isLastPage.value) {
        state.page += 1;
        const options = { term: term.value, page: state.page, pageSize };
        searchProducts(options);
      }
    };

    watch(route, (newRoute) => {
      const searchTerm = newRoute.query.term?.toString() || '';
      const options = { term: searchTerm, page: 1, pageSize, resetProducts: true };
      searchProducts(options);
      state.page = 1;
    });

    return {
      error,
      products,
      isLoadable,
      isNoResults,
      routes,
      route,
      state,
      onLoadMore,
    };
  },
});
