import { ref, Ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { ProductsListKey } from '@/pages/ProductsList/constants/injectables';
import { Product } from '@/shared/interfaces/product';

interface IOptions {
  term?: string;
  page?: number;
  pageSize?: number;
  resetProducts?: boolean;
}

interface ISearch {
  products: Ref<Product[]>;
  error: Ref<boolean>;
  isLoading: Ref<boolean>;
  isLastPage: Ref<boolean>;
  searchProducts: (options: IOptions) => Promise<void>;
}

const DEFAULT_PAGE_SIZE = 20;

export const useSearch = (): ISearch => {
  const service = injectStrict(ProductsListKey);
  const products = ref<Product[]>([]);
  const isLoading = ref<boolean>(false);
  const isLastPage = ref<boolean>(false);
  const error = ref<boolean>(false);

  const searchProducts = async (options: IOptions) => {
    const { term, page, pageSize = DEFAULT_PAGE_SIZE, resetProducts = false } = options;
    isLoading.value = true;
    if (resetProducts) {
      products.value = [];
    }
    try {
      const res = await service.getSearchProducts(term, page, pageSize);
      if (res.status === 200) {
        products.value = [...products.value, ...res.data];
        isLastPage.value = res.data.length < pageSize;
        error.value = false;
      } else {
        error.value = true;
      }
    } catch (err) {
      error.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    searchProducts,
    products,
    error,
    isLoading,
    isLastPage,
  };
};
