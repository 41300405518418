import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white min-h-screen" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!
  const _component_SearchInfoBlock = _resolveComponent("SearchInfoBlock")!
  const _component_SearchNoResults = _resolveComponent("SearchNoResults")!
  const _component_ProductsInfiniteScroll = _resolveComponent("ProductsInfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_Error, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SearchInfoBlock, {
            productsLength: _ctx.products.length,
            searchTerm: _ctx.route.query.term
          }, null, 8, ["productsLength", "searchTerm"]),
          (_ctx.isNoResults)
            ? (_openBlock(), _createBlock(_component_SearchNoResults, { key: 0 }))
            : (_openBlock(), _createBlock(_component_ProductsInfiniteScroll, {
                key: 1,
                products: _ctx.products,
                isLoadable: _ctx.isLoadable,
                page: _ctx.state.page,
                onLoadMore: _ctx.onLoadMore
              }, null, 8, ["products", "isLoadable", "page", "onLoadMore"]))
        ]))
  ]))
}